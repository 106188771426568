import React from 'react';
import Image from 'next/image';
import { Article } from '@/models/HomePage.model';
import { Grid, Button } from '@mui/material';
import { useTranslation } from 'next-i18next';
import AppLink from '../Layout/handler/AppLink';

export default function ArticleList({
  isList,
  articles,
}: {
  articles: Article[] | undefined;
  isList?: boolean;
}) {
  const { t } = useTranslation();

  return articles ? (
    <div className="pt-2 px-1 xl:px-0 ">
      <Grid container spacing={1.5}>
        {articles.map((e, i) => {
          const isFirst = i === 0 && isList === true;
          return (
            <Grid
              key={i}
              item
              xs={12}
              md={isFirst ? 12 : 6}
              lg={isFirst ? 12 : isList ? 4 : 6}
            >
              <div className="bg-white px-2 rounded h-full">
                {e.name !== undefined ? (
                  <div className="flex items-center justify-between py-2">
                    <div className="font-bold text-xl sm:text-2xl">
                      {e.name}
                    </div>
                    {e.tagSlug !== undefined && (
                      <div>
                        <AppLink href={`/articles?tag=${e.tagSlug}`}>
                          <div className="border hover:border-primary px-2 py-0.5 rounded-full flex items-center select-none cursor-pointer text-sm sm:text-base">
                            <div className="text-primary whitespace-nowrap">
                              {t('common:viewall')}
                            </div>
                            <div className="h-4 leading-4 ms-1">
                              <i className="bi bi-arrow-right-circle-fill text-primary"></i>
                            </div>
                          </div>
                        </AppLink>
                      </div>
                    )}
                  </div>
                ) : (
                  <></>
                )}
                <AppLink href={e.slug ? `/articles/${e.slug}` : undefined}>
                  <div
                    className={`${e.name === undefined ? 'pt-2' : ''} ${
                      isFirst ? 'block lg:flex' : 'flex flex-col'
                    }`}
                  >
                    {e.img !== undefined && (
                      <div className={isFirst ? 'pb-2' : 'my-auto'}>
                        <Image
                          src={e.img}
                          alt={e.name ?? ''}
                          width={1200}
                          height={628}
                          className="w-full h-auto rounded"
                        />
                      </div>
                    )}
                    <div className={isFirst ? 'ps-2' : ''}>
                      <div className="font-bold text-lg py-1">
                        <div
                          className={`line-clamp-2 py-2 ${
                            isFirst
                              ? 'text-2xl leading-5 h-11'
                              : 'leading-4 h-10'
                          }`}
                        >
                          {e.subject}
                        </div>
                      </div>
                      <div className="text-base py-1">
                        <div className="leading-4 h-12 line-clamp-3">
                          {e.title}
                        </div>
                      </div>
                      {e.slug !== undefined && (
                        <div className="py-2">
                          <Button
                            size="small"
                            className="hover:opacity-90"
                            style={{
                              fontSize: '1rem',
                              backgroundColor: '#B8292F',
                              color: '#FFFFFF',
                              paddingLeft: 12,
                              paddingRight: 12,
                              paddingTop: 0,
                              paddingBottom: 0,
                            }}
                          >
                            {t('common:continuereading')}
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </AppLink>
              </div>
            </Grid>
          );
        })}
      </Grid>
    </div>
  ) : (
    <></>
  );
}
