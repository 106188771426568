import { ProductSlide as ProductSlideModel } from '@/models/HomePage.model';
import React, { useEffect, useState } from 'react';
import TopicMenu from '../../widgets/TopicMenu';
import AppLink from '../../Layout/handler/AppLink';
import { useTranslation } from 'next-i18next';
import SlickProductCard from '../../common/SlickProductCard';
import { ProductModel } from '@/models/Product.model';
import { getEventData } from '@/services/client/getEventDataService';
import ProductSlideLoading from './Loading';

interface Props {
  data?: ProductSlideModel;
}

export default function ProductSlide({ data }: Props) {
  const { t, i18n } = useTranslation('home');
  const [products, setProducts] = useState<ProductModel[]>();

  useEffect(() => {
    if (data?.type) {
      getEventData({
        lang: i18n.language,
        hotcat: data?.type,
        maxshow: data?.showmax,
      }).then((res) => {
        const resData = res && res.products ? res.products : [];
        setProducts(resData);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.type, i18n.language]);
  return products ? (
    products.length > 0 ? (
      <div
        className="pt-2 px-1 mb-1"
        style={{
          backgroundImage: data?.img,
        }}
      >
        <div className="flex items-center justify-between px-0.5 sm:px-1">
          <div className="w-1/3 hidden md:block"></div>
          <div className="w-1/2 md:w-1/3 flex justify-start md:justify-center">
            <TopicMenu topic={`${data?.name}`} color={data?.fontcolor} />
          </div>
          <div className="w-1/2 md:w-1/3 flex justify-end items-center leading-4 text-sm sm:text-base">
            <AppLink href={`/event/${decodeURI(data?.type ?? '')}`}>
              <div
                className="underline hover:text-primary text-right"
                style={{
                  color: data?.fontcolor,
                }}
              >
                {t('common:viewall')}
              </div>
            </AppLink>
          </div>
        </div>
        <div className="pt-1 pb-1 md:pb-0">
          <SlickProductCard
            arrows
            products={products}
            autoplay={true}
            slidesToShow={4}
            slidesPerRow={products.length < 8 ? 1 : data?.slidesPerRow}
            xs={{
              slidesToShow: 2,
              slidesPerRow: products.length < 8 ? 1 : 1,
            }}
            sm={{
              slidesToShow: 2,
              slidesPerRow: products.length < 8 ? 1 : 1,
            }}
            md={{
              slidesToShow: 2,
              slidesPerRow: products.length < 8 ? 1 : 1,
            }}
            lg={{
              slidesToShow: 3,
              slidesPerRow: products.length < 8 ? 1 : 1,
            }}
          />
        </div>
      </div>
    ) : (
      <></>
    )
  ) : (
    <ProductSlideLoading />
  );
}
