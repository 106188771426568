import { modelValue } from '@/utils/modelValue';
import { ProductModel, ProductModelConverter } from './Product.model';
export interface GetRecentlyViewedModel {
  products?: ProductModel[];
  dfstore?: string;
}

export interface Product {
  sku?: number;
  skcode?: number;
  base64?: string;
  name?: string;
  price?: string;
  slugname?: string;
  brand?: string;
  uname?: string;
  disc?: string;
  per?: string;
  tagnew?: number;
  abtflag?: string;
  hugthai?: number;
  linebt?: string;
  skUstatus?: string;
  seq?: number;
  avgreview?: number;
  totalreview?: number;
  assemble?: number | null;
  freegiftmsg?: null;
  freeinstalbadge?: number;
  warranty?: string;
  warrantycode?: string;
  badgeBuyDisc?: null | string;
  minqty?: number;
  theonepromo?: number;
  backOrderFlag?: boolean;
  stockavail?: number;
  packSize?: number;
  curtainflag?: number;
  spu?: number;
}

export interface AtbBadge {
  skcode?: number;
  base64?: string;
  idx?: number;
}

export class GetRecentlyViewedModelConverter {
  public static fromJson(json: any): GetRecentlyViewedModel {
    const model: GetRecentlyViewedModel = {
      products:
        json['products'] == null
          ? []
          : json['products'].map((x: any) => ProductModelConverter.fromJson(x)),
      dfstore: json['dfstore'],
    };
    return modelValue(model);
  }
}
