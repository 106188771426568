import React, { useEffect, useState } from 'react';
import { ProductModel } from '@/models/Product.model';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import SlickProductCard from './SlickProductCard';
import { useTranslation } from 'next-i18next';
import { getRecentlyViewedData } from '@/services/client/getRecentlyViewedService';
import TopicMenu from '../widgets/TopicMenu';
import * as localStorageKey from '@/constants/localStorageKey.constant';

interface Props {
  currentSku?: string;
}

export default function RecentlyViewed({ currentSku }: Props) {
  const { t, i18n } = useTranslation();
  const [products, setProducts] = useState<ProductModel[]>([]);

  useEffect(() => {
    const recentlylists = localStorage.getItem(localStorageKey.recentlyLists);
    let skgroupArray;
    if (recentlylists) {
      skgroupArray = JSON.parse(recentlylists);
      if (currentSku) {
        const index = skgroupArray.indexOf(currentSku);
        if (index !== -1) {
          skgroupArray.splice(index, 1);
        }
      }
    }
    const skgroupNoSkuSelft = JSON.stringify(skgroupArray);
    if (skgroupNoSkuSelft) {
      getRecentlyViewedData({
        lang: i18n.language,
        skgroup: skgroupNoSkuSelft,
      }).then((getData) => setProducts(getData?.products ?? []));
    }
  }, [currentSku, i18n.language]);

  return products && products.length > 0 ? (
    <div>
      <div className="mt-2 flex items-center justify-center px-0 sm:px-1">
        <TopicMenu topic={`${t('recentlyviewed')}`} />
      </div>
      <div className="pt-1">
        <SlickProductCard
          arrows
          products={products}
          autoplay={false}
          slidesToShow={4}
          xs={{ slidesToShow: 2 }}
          sm={{ slidesToShow: 2 }}
          md={{ slidesToShow: 2 }}
          lg={{ slidesToShow: 4 }}
          infinite={false}
          listName={`recently_view`}
        />
      </div>
    </div>
  ) : (
    <></>
  );
}
