import SliderLayout from '@/components/common/SliderLayout';
import TopicMenu from '@/components/widgets/TopicMenu';
import Skeleton from '@mui/material/Skeleton';
import React from 'react';

interface Props {
  desktopWidth: number;
  desktopHeight: number;
}

export default function PromotionSlideLoading({
  desktopWidth,
  desktopHeight,
}: Props) {
  return (
    <div className="bg-white p-2">
      <div className="flex items-center justify-between">
        <TopicMenu />
        <Skeleton
          variant="rounded"
          style={{
            borderRadius: '9999px',
          }}
          width="112.88px"
          height="44px"
        />
      </div>
      <div className="pt-2 pb-3">
        <SliderLayout
          dots={false}
          arrows={false}
          autoplay={false}
          centerMode
          slidesToShow={3}
          xs={{ slidesToShow: 1 }}
          sm={{ slidesToShow: 1 }}
          md={{ slidesToShow: 2 }}
          lg={{ slidesToShow: 2 }}
        >
          {[...Array(5)]?.map((e, i) => (
            <div key={`slider-${i}`} className="px-1 md:px-1.5">
              <div
                className={`relative w-full`}
                style={{
                  paddingTop: `calc(${desktopHeight}/${desktopWidth}*100%)`,
                }}
              >
                <Skeleton
                  variant="rectangular"
                  className="rounded-md absolute top-0 left-0"
                  style={{
                    width: '100%',
                    height: '100%',
                  }}
                />
              </div>
            </div>
          ))}
        </SliderLayout>
      </div>
    </div>
  );
}
