import { Banner } from '@/models/HomePage.model';
import { customerInfoSelector } from '@/store/slices/authenSlice';
import { useRouter } from 'next/router';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import Image from 'next/image';

interface Props {
  banner: Banner;
  sectionName: string;
  position: number; //ต้อง +1 มากจาก index ของ loop เสมอ ถ้าไม่ได้มาจาก loop ใส่ 1 ใส่ไปเลย
  width: number;
  height: number;
  loading?: 'lazy' | 'eager' | undefined;
  style?: React.CSSProperties;
  imageRounded?: boolean;
}

export default function PromotionImageImpression({
  sectionName,
  banner,
  position,
  width,
  height,
  loading,
  style,
  imageRounded,
}: Props) {
  const router = useRouter();
  const customerInfo = useSelector(customerInfoSelector);
  const customerId = customerInfo?.custId;
  const promotionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const promotion = promotionRef.current;
    if (!promotion || !(window as any).dataLayer || customerInfo === undefined)
      return;

    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        (window as any).dataLayer.push({
          event: 'promotionView',
          userId: customerId,
          ecommerce: {
            promoView: {
              promotions: [
                {
                  name: banner.topic,
                  position: `${sectionName}_${position}`,
                },
              ],
            },
          },
        });

        observer.disconnect();
      }
    });

    observer.observe(promotion);

    return () => {
      if (promotion && observer) {
        observer.unobserve(promotion);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId]);

  const handleBannerClick = () => {
    if (banner.url) {
      if ((window as any).dataLayer) {
        (window as any).dataLayer.push({
          event: 'promotionClick',
          userId: customerId,
          ecommerce: {
            promoClick: {
              promotions: [
                {
                  name: banner.topic,
                  position: `${sectionName}_${position}`,
                },
              ],
            },
          },
        });
      }
      try {
        if ((window as any).appier) {
          (window as any).appier('event', 'promotion_viewed', {
            promotion_name: banner.topic,
            promotion_link: `${process.env.NEXT_PUBLIC_BASE_URL_WEB}${banner.url}`,
            promotion_image: banner.img,
            env: process.env.NEXT_PUBLIC_NODE_ENV,
          });
        }
      } catch (_) {}
      if (banner.ahref) {
        window.open(`/${router.locale}/${banner.url}`, '_blank');
      } else {
        router.push(`/${router.locale}/${banner.url}`);
      }
    }
  };

  return (
    <div
      onClick={handleBannerClick}
      ref={promotionRef}
      className={`${banner.url ? 'cursor-pointer' : ''}`}
    >
      {process.env.NEXT_PUBLIC_CMS_USE_NEXT_IMAGE === '1' ? (
        <>
          <div className="hidden md:block">
            <Image
              src={banner.img}
              alt={banner.topic}
              width={width}
              height={height}
              loading={loading}
              style={style}
              className={imageRounded ? 'rounded-md' : ''}
            />
          </div>
          <div className="block md:hidden">
            <Image
              src={banner.imgMobile ?? banner.img}
              alt={banner.topic}
              width={width}
              height={height}
              loading={loading}
              style={style}
              className={imageRounded ? 'rounded-md' : ''}
            />
          </div>
        </>
      ) : (
        // eslint-disable-next-line @next/next/no-img-element
        <img
          src={banner.img}
          alt={banner.topic}
          width={width}
          height={height}
          loading={loading}
          style={style}
          className={imageRounded ? 'rounded-md' : ''}
        />
      )}
    </div>
  );
}
