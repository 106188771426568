import {
  GetRecentlyViewedModel,
  GetRecentlyViewedModelConverter,
} from '@/models/GetRecentlyViewed.model';
import { postDataFromAPI } from '@/utils/httpClient';

export const getRecentlyViewedData = async ({
  lang,
  skgroup,
}: {
  lang: string;
  skgroup?: string;
}): Promise<GetRecentlyViewedModel> => {
  const [getApiData] = await Promise.all([
    postDataFromAPI({
      path: '/getrecentlyviewed',
      lang,
      body: { lang, skgroup },
    }),
  ]);
  const apiData =
    getApiData.status === 200
      ? getApiData.data
        ? getApiData.data
        : undefined
      : undefined;
  let response: GetRecentlyViewedModel = apiData;
  if (apiData) {
    try {
      response = GetRecentlyViewedModelConverter.fromJson(apiData);
    } catch (error) {
      console.log(error);
    }
  }

  return response;
};
