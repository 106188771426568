import { Beyondproject } from '@/models/HomePage.model';
import React, { useState } from 'react';
import Image from 'next/image';

interface Props {
  project: Beyondproject;
  className?: string;
}

export default function VideoProjectCard({ project, className }: Props) {
  const [isClick, setIsClick] = useState<boolean>(false);

  const imageThumbnail = `${
    project.url
      ?.replace('www.youtube.com/embed/', 'i.ytimg.com/vi/')
      ?.replace('youtu.be/', 'i.ytimg.com/vi/')
      ?.replace('www.youtube.com/watch?v=', 'i.ytimg.com/vi/')
      ?.split('?')[0] + '/sddefault.jpg'
  }`;

  return (
    <div className={`h-[180px] md:h-[250px] relative ${className}`}>
      {isClick ? (
        <iframe
          title={project.subject}
          width="100%"
          height="100%"
          src={project.url}
          className="rounded"
          allow="autoplay"
        ></iframe>
      ) : (
        <>
          <Image
            src={
              imageThumbnail.includes('http://') ||
              imageThumbnail.includes('https://')
                ? imageThumbnail
                : `https://${imageThumbnail}`
            }
            alt="Image Thumbnail"
            width={640}
            height={480}
            className="rounded"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
            }}
          />
          <div
            className="rounded absolute w-full h-full bg-black top-0 left-0 bg-opacity-30 flex justify-center items-center cursor-pointer select-none"
            onClick={(e: any) => {
              e.preventDefault();
              e.stopPropagation();
              setIsClick(true);
            }}
          >
            <Image
              src="/images/svg/youtube_play_button.svg"
              alt="youtube play button"
              width={68}
              height={48}
            />
            <div className="absolute top-0 left-0 flex items-center mt-1.5 px-2">
              <div className="rounded-full bg-white h-7 w-7">
                <Image
                  src="/images/svg/logo_twd.svg"
                  alt="logo twd"
                  width={119}
                  height={158}
                  className="w-full h-full object-contain p-1"
                />
              </div>
              <div className="text-white ps-1.5 leading-4 h-4 line-clamp-1 w-[calc(100%-1.75rem)]">
                {project.subject}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
