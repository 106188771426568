import React, { useEffect, useState } from 'react';
import CustomModal from '../common/CustomModal';
import { AwPopup as AwPopupModel } from '@/models/HomePage.model';
import heroBannerClass from '@/styles/herobanner.module.css';
import { useTranslation } from 'next-i18next';
import CheckBoxCompare from '../common/CheckBoxCompare';
import { ActionWrapper } from '../common/CmsWrapper';
import AppLink from '../Layout/handler/AppLink';
import Image from 'next/image';

interface Props {
  awPopupData?: AwPopupModel;
}

export default function AwPopup({ awPopupData }: Props) {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const [doNotShow, setDoNotShow] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (awPopupData?.version) {
      if (
        localStorage.getItem('aw_version') === awPopupData?.version?.toString()
      ) {
        setOpen(false);
      } else {
        setOpen(true);
      }

      return () => {
        if (doNotShow) {
          localStorage.setItem(
            'aw_version',
            awPopupData?.version?.toString() ?? '',
          );
        }
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [awPopupData]);

  useEffect(() => {
    if (open === false && doNotShow) {
      localStorage.setItem(
        'aw_version',
        awPopupData?.version?.toString() ?? '',
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const srcRegex = /<img [^>]*src=["']([^"']+)["'][^>]*>/;
  const match = (awPopupData?.content ?? '').match(srcRegex);

  const aRegex = /<a [^>]*href=["']([^"']+)["'][^>]*>/;
  const amatch = (awPopupData?.content ?? '').match(aRegex);

  return awPopupData ? (
    <CustomModal
      open={open}
      onClose={() => setOpen(false)}
      showCloseButton={!loading}
      closeButtonColor="white"
      bgColor="[#00000000]"
      isNoShadow
      isWidthAlwayAuto
    >
      <div className="overflow-hidden min-w-[80vw] md:min-w-[20vw] p-3">
        <div className="flex flex-col cms-wrapper">
          <ActionWrapper>
            <AppLink
              isFullUrl
              href={amatch && amatch.length > 1 ? amatch[1] : undefined}
              ariaLabel="Aw popup"
            >
              {match && match.length > 1 && (
                // eslint-disable-next-line @next/next/no-img-element
                <Image
                  src={match[1]}
                  alt="aw-popup"
                  width={1360}
                  height={1360 / (64 / 19)}
                  onLoad={() => {
                    setLoading(false);
                  }}
                  className="w-auto max-h-[80vh]"
                />
              )}
              <div
                className={`mb-1.5 ${heroBannerClass.awPopupContent} mx-auto`}
                dangerouslySetInnerHTML={{
                  __html: (awPopupData.content ?? '')
                    .replace(/style="[^"]*"/g, '')
                    .replace(/<img [^>]*>/g, '')
                    .replace(/<a [^>]*>/g, ''),
                  // .replaceAll(
                  //   '<img ',
                  //   `<img width="1360"
                  // height="${1360 / (64 / 19)}" `,
                  // ),
                }}
              />
            </AppLink>
          </ActionWrapper>
          {!loading && (
            <div
              className="flex items-center cursor-pointer ms-auto select-none me-1 pb-1 text-white"
              onClick={() => setDoNotShow(!doNotShow)}
            >
              <div className="h-3.5">
                <CheckBoxCompare color="white" value={doNotShow} />
              </div>
              <div className="ms-1 text-sm leading-3 font-semibold drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,1)]">
                {t('donotshowthispageagain')}
              </div>
            </div>
          )}
        </div>
      </div>
    </CustomModal>
  ) : (
    <></>
  );
}
