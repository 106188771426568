/* eslint-disable react-hooks/rules-of-hooks */
import React, { useContext } from 'react';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { getHomePageData } from '@/services/server/homePageService';
import { CMSHomePageModel } from '@/models/HomePage.model';
import useSWR from 'swr';
import { getTopBannerData } from '@/services/client/topBannerService';
import TopBannerContext from '@/contexts/TopBannerContext';
// import PromotionEvent from '@/components/homepage/PromotionEvent';
import ShopByBrand from '@/components/homepage/ShopByBrand';
import FlashSale from '@/components/homepage/FlashSale';
import Head from 'next/head';
import { setCacheServerSideProps } from '@/utils/serverSidePropsInterceptor';
import { GetServerSidePropsContext } from 'next';
import AwPopup from '@/components/homepage/AwPopup';
import ArticleList from '@/components/homepage/ArticleList';
import HeroMenu from '@/components/homepage/HeroMenu';
import SpecialMenu from '@/components/homepage/SpecialMenu';
import PromotionSlide from '@/components/homepage/PromotionSlide';
import ProductSlide from '@/components/homepage/ProductSlide';
import PromotionBanner from '@/components/homepage/PromotionBanner';
import CategorySlide from '@/components/homepage/CategorySlide';
import ShopByStyle from '@/components/homepage/ShopByStyle';
import ShopByRoom from '@/components/homepage/ShopByRoom';
import RecentlyViewed from '@/components/common/RecentlyViewed';
import VideoProjects from '@/components/common/VideoProjects';
import { useTranslation } from 'next-i18next';
import { useSelector } from 'react-redux';
import { currentStoreSelector } from '@/store/slices/storeSlice';
import { fetchTranslationsData } from '@/utils/fetchTranslationsData';
import FloatingEvent from '@/components/homepage/FloatingEvent';
import ProductSlideLoading from '@/components/homepage/ProductSlide/Loading';
import { staticLang } from '@/constants/defaultValue.constant';

export default function index() {
  const { i18n } = useTranslation();
  const { setTopBanner } = useContext(TopBannerContext);
  const currentStore = useSelector(currentStoreSelector);

  // Call API to get homepage data
  const {
    data,
    error,
    isLoading,
  }: {
    data?: CMSHomePageModel;
    error?: any;
    isLoading?: boolean;
  } = useSWR(['cmsHomepage', i18n.language], () =>
    getHomePageData({ lang: i18n.language }).then((res) => res),
  );

  // Call API to get top banner data
  useSWR(['cmsVersionLoad', i18n.language], () =>
    getTopBannerData({ lang: i18n.language }).then((res) => {
      if (res) {
        setTopBanner(res);
      }
    }),
  );

  return (
    <>
      <Head>
        <meta
          data-react-helmet="true"
          property="og:description"
          content="ไทวัสดุ ศูนย์รวมสินค้าบ้านและวัสดุก่อสร้างอันดับ 1 ของคนไทย เลือกซื้อวัสดุก่อสร้าง ของตกแต่งบ้านมากกว่า 50,000 รายการได้แล้ววันนี้"
        />
        <meta
          data-react-helmet="true"
          itemProp="description"
          name="description"
          content="ไทวัสดุ ศูนย์รวมสินค้าบ้านและวัสดุก่อสร้างอันดับ 1 ของคนไทย เลือกซื้อวัสดุก่อสร้าง ของตกแต่งบ้านมากกว่า 50,000 รายการได้แล้ววันนี้"
        />
        <meta
          data-react-helmet="true"
          name="keywords"
          content="ไทวัสดุ, thaiwatsadu, วัสดุก่อสร้าง, ของแต่งบ้าน, ซื้อเหล็กออนไลน์, เหล็ก, ปูน, กระเบื้อง, CRC, Central retail"
        />
        <meta
          data-react-helmet="true"
          name="og:keywords"
          content="ไทวัสดุ, thaiwatsadu, วัสดุก่อสร้าง, ของแต่งบ้าน, ซื้อเหล็กออนไลน์, เหล็ก, ปูน, กระเบื้อง, CRC, Central retail"
        />
        <meta
          itemProp="image"
          content="https://www.thaiwatsadu.com/images/svg/footer/shop_easy_every_life_style_desktop_th.svg"
        />
        <meta
          itemProp="name"
          content="THAI WATSADU | ไทวัสดุ ครบเรื่องบ้าน ถูกและดี"
        />
        {/*  Facebook Meta Tags  */}
        <meta
          data-react-helmet="true"
          property="og:url"
          content="https://www.thaiwatsadu.com"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="THAI WATSADU | ไทวัสดุ ครบเรื่องบ้าน ถูกและดี"
        />
        <meta
          property="og:description"
          content="ไทวัสดุ ศูนย์รวมสินค้าบ้านและวัสดุก่อสร้างอันดับ 1 ของคนไทย เลือกซื้อวัสดุก่อสร้าง ของตกแต่งบ้านมากกว่า 50,000 รายการได้แล้ววันนี้"
        />
        <meta
          property="og:image"
          content="https://www.thaiwatsadu.com/images/svg/footer/shop_easy_every_life_style_desktop_th.svg"
        />

        {/* Twitter Meta Tags  */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="THAI WATSADU | ไทวัสดุ ครบเรื่องบ้าน ถูกและดี"
        />
        <meta
          name="twitter:description"
          content="ไทวัสดุ ศูนย์รวมสินค้าบ้านและวัสดุก่อสร้างอันดับ 1 ของคนไทย เลือกซื้อวัสดุก่อสร้าง ของตกแต่งบ้านมากกว่า 50,000 รายการได้แล้ววันนี้"
        />
        <meta
          name="twitter:image"
          content="https://www.thaiwatsadu.com/images/svg/footer/shop_easy_every_life_style_desktop_th.svg"
        />
      </Head>

      {/* Awpopup */}
      <AwPopup awPopupData={data?.awPopups} />

      {/* HeroBanner */}
      <HeroMenu banners={isLoading ? undefined : error ? [] : data?.data} />

      {/* SpecialMenu */}
      <SpecialMenu />

      {/* FlashSale */}
      <FlashSale flashsale={isLoading ? undefined : data?.flashsale} />

      {/* DealOfTheDay */}
      {isLoading ? (
        <ProductSlideLoading />
      ) : (
        data?.dealofthedays?.map((e, i) => <ProductSlide key={i} data={e} />)
      )}

      {/* HotPromotions Other */}
      {isLoading
        ? undefined
        : data?.hotpromotions
            ?.filter((e) => e.type?.includes('dealoftheday'))
            ?.map((e, i) => (
              <ProductSlide
                key={i}
                data={{ ...e, slidesPerRow: 2, fontcolor: '#FCC800' }}
              />
            ))}

      {/* Promotion Slide */}
      <PromotionSlide
        banners={isLoading ? undefined : data?.promotionbannersslide}
      />

      {/* PromotionBanner */}
      <PromotionBanner
        promotionbanners={isLoading ? undefined : data?.promotionbanners}
      />

      {/* HotPromotions Only New */}
      {isLoading
        ? undefined
        : data?.hotpromotions
            ?.filter((e) => !e.type?.includes('dealoftheday'))
            ?.map((e, i) => <ProductSlide key={i} data={e} />)}

      {/* Category List */}
      <CategorySlide
        isBnb={currentStore?.isHybrid === true ? false : undefined}
      />

      {/* Category List */}
      {currentStore?.isHybrid === true && <CategorySlide isBnb={true} />}

      {/* Shop by style */}
      <ShopByStyle />

      {/* Brand List */}
      <ShopByBrand
        brands={
          isLoading
            ? undefined
            : data?.brand && data?.brand && data.brand.length > 0
            ? data?.brand
            : []
        }
      />

      {/* Shop by room */}
      {data?.shopbyroom && <ShopByRoom shopByRoom={data?.shopbyroom} />}

      {/* Recently viewed*/}
      <RecentlyViewed />

      {/* Video projects*/}
      <VideoProjects projects={data?.beyondproject} />

      {/* article */}
      <ArticleList articles={data?.articles} />

      {/* floating event */}
      <FloatingEvent data={data?.floatingEvent} />

      <div className="pb-2"></div>
    </>
  );
}

export async function getServerSideProps(context: GetServerSidePropsContext) {
  setCacheServerSideProps(context.res);
  return process.env.NEXT_PUBLIC_REALTIME_LANG === '1'
    ? {
        props: {
          ...(await fetchTranslationsData(context.locale ?? 'th', [
            ...staticLang,
            'home',
            'productcard',
          ])),
        },
      }
    : {
        props: {
          ...(await serverSideTranslations(context.locale ?? 'th', [
            ...staticLang,
            'home',
            'productcard',
          ])),
        },
      };
}
