import React from 'react';
import { useTranslation } from 'next-i18next';
import TopicMenu, { TopicMenuType } from '../widgets/TopicMenu';
import SliderLayout from '../common/SliderLayout';
import AppLink from '../Layout/handler/AppLink';
import Image from 'next/image';
import AllCatContext from '@/contexts/AllCatContext';
import { Skeleton } from '@mui/material';

interface Props {
  isBnb?: boolean;
}

export default function CategorySlide({ isBnb }: Props) {
  const { t, i18n } = useTranslation();
  const desktopWidth = 631;
  const desktopHeight = 341;

  return (
    <div className="mb-2">
      <AllCatContext.Consumer>
        {(allcats) => {
          if (isBnb === true) {
            allcats = allcats?.filter((e) => e.isHybrid === true);
          } else if (isBnb === false) {
            allcats = allcats?.filter((e) => e.isHybrid !== true);
          }
          return (
            <div className="p-2 bg-white">
              <div className="flex items-center justify-center px-0 sm:px-1">
                <TopicMenu
                  topic={
                    !allcats || allcats.length === 0
                      ? undefined
                      : `${
                          isBnb === true ? t('home:homegoodsby') : t('category')
                        }`
                  }
                  color={isBnb === true ? '#006E63' : undefined}
                  type={
                    isBnb === true
                      ? TopicMenuType.bnbhome
                      : TopicMenuType.thaiwatsadu
                  }
                />
              </div>
              <div className="pt-2 pb-3">
                <SliderLayout
                  dots={true}
                  arrows={true}
                  slidesToShow={6}
                  slidesPerRow={2}
                  xs={{
                    slidesToShow: 3,
                    slidesPerRow: 2,
                  }}
                  sm={{
                    slidesToShow: 3,
                    slidesPerRow: 2,
                  }}
                  md={{
                    slidesToShow: 3,
                    slidesPerRow: 2,
                  }}
                  lg={{
                    slidesToShow: 3,
                    slidesPerRow: 2,
                  }}
                >
                  {!allcats || allcats.length === 0
                    ? [...Array(12)].map((e, i) => (
                        <div
                          key={`slider-${i}`}
                          className="px-1 md:px-1.5 select-none"
                        >
                          <div
                            className={`relative w-full pb-4`}
                            style={{
                              paddingTop: `calc(${desktopHeight}/${desktopWidth}*100%)`,
                            }}
                          >
                            <Skeleton
                              variant="rectangular"
                              className="rounded-md absolute top-0 left-0"
                              style={{
                                width: '100%',
                                height: '100%',
                              }}
                            />
                          </div>
                          <div className="my-1 text-center font-semibold leading-4 h-8 line-clamp-2">
                            <Skeleton variant="text" />
                            <Skeleton variant="text" width="70%" />
                          </div>
                        </div>
                      ))
                    : allcats?.map((e, i) => (
                        <div
                          key={`slider-${i}`}
                          className="px-1 md:px-1.5 select-none"
                        >
                          <AppLink
                            href={`/category/${e.slugname}`}
                            className='select-none"'
                          >
                            <div className="bg-[#F1F1F1] py-2 rounded">
                              <Image
                                src={`/images/homepage/catimage/${e.node}.png`}
                                alt={`cat-${e.node}`}
                                width={desktopWidth}
                                height={desktopHeight}
                              />
                            </div>
                            <div className="my-1 text-center font-semibold leading-4 h-8 line-clamp-2">
                              {i18n.language == 'th'
                                ? e.catname
                                : e.catnameEN ?? e.catname}
                            </div>
                          </AppLink>
                        </div>
                      ))}
                </SliderLayout>
              </div>
            </div>
          );
        }}
      </AllCatContext.Consumer>
    </div>
  );
}
