import { ShopByStyleModel } from '@/models/HomePage.model';
import { getShopByStyleData } from '@/services/server/homePageService';
import { useTranslation } from 'next-i18next';
import React from 'react';
import useSWR from 'swr';
import AppLink from '../Layout/handler/AppLink';
import ProductImageBase64 from '../common/ProductImageBase64';
import SliderLayout from '../common/SliderLayout';

export default function ShopByStyle() {
  const { t, i18n } = useTranslation('home');
  // Call API to get shop by style data
  const {
    data,
  }: {
    data?: ShopByStyleModel[];
  } = useSWR('shopByStyleHomepage', () =>
    getShopByStyleData({ lang: i18n.language }).then((res) => res),
  );

  let dataUseList: ShopByStyleModel[][][] = [];
  let dataUseListMobile: ShopByStyleModel[][] = [];

  if (data) {
    let dataUse: ShopByStyleModel[][] = [];
    let temp: ShopByStyleModel[] = [];
    let tempMobile: ShopByStyleModel[] = [];
    for (let i = 0; i < data.length; i++) {
      const element = data[i];
      tempMobile.push(element);
      if (tempMobile.length === 3 || i === data.length - 1) {
        dataUseListMobile.push(tempMobile);
        tempMobile = [];
      }
      if (dataUse.length === 3) {
        temp.push(element);
        if (temp.length === 2) {
          dataUse.push(temp);
          dataUseList.push(dataUse);
          dataUse = [];
          temp = [];
        }
      } else if (dataUse.length === 0 && temp.length === 0) {
        dataUse = [[element]];
        temp = [];
      } else {
        if (temp.length === 1) {
          temp.push(element);
          dataUse.push(temp);
          temp = [];
        } else {
          temp.push(element);
        }
      }
      if (i === data.length - 1) {
        if (temp.length !== 0) {
          dataUse.push(temp);
        }
        if (dataUse.length !== 0) {
          dataUseList.push(dataUse);
        }
        if (tempMobile.length !== 0) {
          dataUseListMobile.push(temp);
        }
      }
    }
  }
  return dataUseList.length > 0 ? (
    <div className="pt-1 mb-2 bg-white">
      <div className="pt-1 px-2 flex items-center justify-between">
        <div className="font-bold text-2xl leading-5">{t('shopbystyle')}</div>
        <div>
          <AppLink href="/category/Shop-by-lifestyles-82">
            <div className="border hover:border-primary px-2 py-0.5 rounded-full flex items-center select-none cursor-pointer text-sm sm:text-base">
              <div className="text-primary whitespace-nowrap">
                {t('common:viewall')}
              </div>
              <div className="h-4 leading-4 ms-1">
                <i className="bi bi-arrow-right-circle-fill text-primary"></i>
              </div>
            </div>
          </AppLink>
        </div>
      </div>
      <div className="px-1 xl:px-0 hidden md:block mb-5">
        <SliderLayout
          dots={true}
          arrows={true}
          slidesToShow={1}
          infinite={false}
        >
          {dataUseList.map((dataUse, i) => (
            <div key={i} className="grid grid-cols-9 gap-3 px-3">
              {dataUse.map((data, j) => (
                <div key={j} className={`col-span-${j === 0 ? '3' : '2'}`}>
                  {data.map((e, k) => (
                    <div className="mt-3" key={k}>
                      <AppLink href={`/category/${e.slugname}`}>
                        <div className="relative">
                          <ProductImageBase64
                            pimImage={e.pimImage}
                            base64={e.base64}
                            objectFit="cover"
                            className={`rounded w-full ${
                              j === 0 ? 'h-[434px]' : 'h-[207px]'
                            }`}
                          />
                          <div className="absolute rounded-b bottom-0 left-0 w-full min-h-1/3 flex flex-col text-white bg-gradient-to-r from-[#333333] to-transparent leading-4 px-2 py-1.5 bg-blend-saturation">
                            <div className="font-semibold mt-auto">
                              {e.name}
                            </div>
                          </div>
                        </div>
                      </AppLink>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          ))}
        </SliderLayout>
      </div>
      <div className="px-1 xl:px-0 block md:hidden mt-2 mb-5">
        <SliderLayout
          dots={true}
          arrows={true}
          slidesToShow={1}
          infinite={false}
        >
          {dataUseListMobile.map((dataUse, i) => (
            <div key={i} className="grid grid-cols-2 gap-2">
              {dataUse.map((e, j) => (
                <div
                  key={j}
                  className={`col-span-${
                    j === 0 ? '2' : dataUse.length < 3 ? '2' : '1'
                  }`}
                >
                  <AppLink href={`/category/${e.slugname}`}>
                    <div className="relative">
                      <ProductImageBase64
                        pimImage={e.pimImage}
                        base64={e.base64}
                        objectFit="cover"
                        className="rounded w-full h-[170px]"
                      />
                      <div className="absolute rounded-b bottom-0 left-0 w-full min-h-1/3 flex flex-col text-white bg-gradient-to-r from-[#333333] to-transparent leading-4 px-2 py-1.5 bg-blend-saturation">
                        <div className="font-semibold mt-auto">{e.name}</div>
                      </div>
                    </div>
                  </AppLink>
                </div>
              ))}
            </div>
          ))}
        </SliderLayout>
      </div>
    </div>
  ) : (
    <></>
  );
}
