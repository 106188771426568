import { Banner } from '@/models/HomePage.model';
import { Grid } from '@mui/material';
import React from 'react';
import PromotionImageImpression from '../common/PromotionImageImpression';

interface Props {
  promotionbanners?: Banner[][];
}

export default function PromotionBanner({ promotionbanners }: Props) {
  return (
    <>
      {promotionbanners ? (
        <div>
          {promotionbanners.map((promotionbanner, i) => (
            <div key={i} className="pt-1">
              <Grid spacing={1} container>
                {promotionbanner.map((banner, j) => (
                  <Grid key={`col-${j}`} item xs={12} lg={banner.col ?? 12}>
                    <PromotionImageImpression
                      sectionName="promotionbanner" // ถ้ามีหัวข้อจาก CMS ก็เอามาใส่
                      position={i + 1}
                      banner={banner}
                      width={1360}
                      height={43}
                      loading="lazy"
                      style={{ width: '100%', height: 'auto' }}
                    />
                  </Grid>
                ))}
              </Grid>
            </div>
          ))}
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
