import React from 'react';
import Grid from '@mui/material/Grid';
import Image from 'next/image';
import AppLink from '../Layout/handler/AppLink';
import { useTranslation } from 'next-i18next';
import { tailorMadePath } from '@/constants/defaultValue.constant';

interface SpecialMenuModel {
  image: string;
  title?: string;
  description?: string;
  href?: string;
}

export default function SpecialMenu() {
  const { t } = useTranslation();
  const menuList: SpecialMenuModel[] = [
    {
      image: '/images/homepage/steel.jpg',
      title: t('buysteel') ?? '',
      description: t('steelproduct') ?? '',
      href: '/category/เหล็ก-51',
    },
    {
      image: '/images/homepage/paint.jpg',
      title: t('buypaint') ?? '',
      description: t('byspecifypaintnumber') ?? '',
      href: '/page/digital-color-mixed-paint',
    },
    {
      image: '/images/homepage/solarsystem.png',
      title: t('solarcellsystem') ?? '',
      description: t('solarfullservice') ?? '',
      href: '/category/ระบบโซลาร์เซลล์-6110',
    },
    {
      image: '/images/homepage/curtain.jpg',
      title: t('customcurtain') ?? '',
      description: t('selectcurtainyourself') ?? '',
      href: tailorMadePath,
    },
  ];
  return (
    <div className="px-1 xl:px-0 py-2">
      <Grid container spacing={1}>
        {menuList.map((item, index) => (
          <Grid key={index} item xs={6} sm={6} lg={3}>
            <AppLink href={item.href}>
              <div className="flex p-1 bg-white rounded h-full">
                <Image
                  src={item.image}
                  alt={item.title ?? ''}
                  width={100}
                  height={100}
                  className="w-[65px] h-[65px] sm:w-[80px] sm:h-[80px] xl:w-[100px] xl:h-[100px] rounded my-auto"
                />
                <div className="ps-2">
                  <div className="font-semibold leading-4 md:leading-4 xl:leading-4 text-lg md:text-xl xl:text-2xl py-1">
                    {item.title ?? ''}
                  </div>
                  <div className="leading-3 md:leading-4 text-sm md:text-base line-clamp-3">
                    {item.description ?? ''}
                  </div>
                </div>
                <div className="ms-auto my-auto">
                  <i className="bi bi-chevron-right text-sm md:text-base"></i>
                </div>
              </div>
            </AppLink>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
