import { getDataFromCMS, postDataFromAPI } from '@/utils/httpClient';
import {
  CMSHomePageModel,
  CMSHomePageModelConverter,
  ShopByStyleModel,
  ShopByStyleModelConverter,
  SkuRoomData,
  SkuRoomDataConverter,
} from '@/models/HomePage.model';
import {
  CMSCategoryPageModel,
  CMSCategoryPageModelConverter,
} from '@/models/CMS.model';

export const getShopByRoomSkuData = async ({
  lang,
  skus,
}: {
  lang?: string;
  skus: string[];
}): Promise<SkuRoomData[] | undefined> => {
  if (skus.length === 0) {
    return [];
  }
  const [getData] = await Promise.all([
    postDataFromAPI({
      path: '/skuroomdata',
      lang,
      body: { skgroup: skus.join(',') },
    }),
  ]);
  const data =
    getData.status == 200
      ? getData.data
        ? getData.data
        : undefined
      : undefined;
  let response: SkuRoomData[] | undefined = data;
  if (data) {
    try {
      response = data.map((e: any) => SkuRoomDataConverter.fromJson(e));
    } catch (error) {
      console.log(error);
    }
  }
  return response;
};

export const getShopByStyleData = async ({
  lang,
}: {
  lang?: string;
}): Promise<ShopByStyleModel[] | undefined> => {
  const [getData] = await Promise.all([
    postDataFromAPI({ path: '/getcatshopbystyle', lang }),
  ]);
  const data =
    getData.status == 200
      ? getData.data
        ? getData.data
        : undefined
      : undefined;
  let response: ShopByStyleModel[] | undefined = data?.products;
  if (data?.products) {
    try {
      response = data?.products.map((e: any) =>
        ShopByStyleModelConverter.fromJson(e),
      );
    } catch (error) {
      console.log(error);
    }
  }
  return response;
};

export const getHomePageData = async ({
  lang,
}: {
  lang?: string;
}): Promise<CMSHomePageModel | undefined> => {
  const [getDataCms] = await Promise.all([
    getDataFromCMS({ path: '/api/banner', lang }),
  ]);
  const cmsData =
    getDataCms.status == 200
      ? getDataCms.data
        ? getDataCms.data
        : undefined
      : undefined;
  let response: CMSHomePageModel | undefined = cmsData;
  if (cmsData) {
    try {
      response = CMSHomePageModelConverter.fromJson(cmsData);
    } catch (error) {
      console.log(error);
    }
  }
  return response;
};

export const getHomepageBlock = async ({
  lang,
  name,
}: {
  lang?: string;
  name: string;
}): Promise<CMSCategoryPageModel | undefined> => {
  const [getDataCms] = await Promise.all([
    getDataFromCMS({
      path: '/api/plpbanner',
      lang,
      param: {
        strqry: `homepage_${name}`,
      },
    }),
  ]);
  const cmsData =
    getDataCms.status == 200
      ? getDataCms.data
        ? getDataCms.data
        : undefined
      : undefined;
  let response: CMSCategoryPageModel | undefined = cmsData;
  if (cmsData) {
    try {
      response = CMSCategoryPageModelConverter.fromJson(cmsData);
    } catch (error) {
      console.log(error);
    }
  }
  return response;
};
