import { floatingEvent } from '@/models/HomePage.model';
import React, { useState } from 'react';
import Image from 'next/image';
import AppLink from '@/components/Layout/handler/AppLink';

interface Props {
  data?: floatingEvent[];
}

export default function FloatingEvent({ data }: Props) {
  const [isShow, setIsShow] = useState<boolean>(false);
  const handleClickEvent = () => {
    setIsShow(!isShow);
  };
  return (
    <div>
      {data && data.length > 0 && (
        <div
          className={`floatingEvent ${
            isShow ? 'show' : 'close'
          } fixed top-1/2 right-0`}
          style={{ zIndex: '99' }}
        >
          {data
            .filter((el) => el.status === true)
            .map((item, i) => (
              <div key={i} className="relative">
                <div
                  className={`cursor-pointer absolute ${
                    isShow
                      ? 'left-[-1.5rem] lg:left-[-2.8rem]'
                      : 'left-[-3.5rem] lg:left-[-5.4rem]'
                  }`}
                  style={{
                    zIndex: '99',
                    top: '0rem',
                  }}
                  onClick={handleClickEvent}
                >
                  <div className="w-[75px] h-[75px] lg:w-[120px] lg:h-[120px]">
                    <Image
                      src={item.imageCircle ?? '/images/svg/no-img.svg'}
                      alt="event image"
                      width={120}
                      height={120}
                    />
                  </div>
                </div>
                <div
                  className={`px-1 lg:px-2 py-2 min-w-40 ${
                    isShow
                      ? 'h-[75px] lg:h-[120px] pl-[1.3rem] lg:pl-[2.5rem]'
                      : 'hidden'
                  }`}
                  style={{
                    backgroundImage: `url(${item.imageBackground})`,
                    backgroundSize: 'cover',
                  }}
                >
                  <div className="flex justify-end lg:justify-between gap-2 lg:gap-3 my-auto h-full">
                    <div
                      className="font-semibold my-auto text-sm lg:text-base"
                      style={{ lineHeight: 1 }}
                    >
                      <div
                        className={`text-[${item.label?.firstFontColor}] ps-3 lg:ps-0`}
                        style={{
                          color: `${item.label?.firstFontColor}`,
                          textShadow: `-1px 0 ${item.label?.firstFontStoke}, 0 1px ${item.label?.firstFontStoke}, 1px 0 ${item.label?.firstFontStoke}, 0 -1px ${item.label?.firstFontStoke}`,
                        }}
                      >
                        {item.label?.firstTitle}
                      </div>
                      <div
                        className={`text-[${item.label?.secondFontColor}] ps-2 lg:ps-0`}
                        style={{
                          color: `${item.label?.secondFontColor}`,
                          textShadow: `-1px 0 ${item.label?.secondFontStoke}, 0 1px ${item.label?.secondFontStoke}, 1px 0 ${item.label?.secondFontStoke}, 0 -1px ${item.label?.secondFontStoke}`,
                        }}
                      >
                        {' '}
                        {item.label?.secondTitle}
                      </div>
                    </div>
                    <AppLink
                      href={`${item.slug}`}
                      target="_blank"
                      className="my-auto text-sm lg:text-base"
                    >
                      <div
                        className={`text-white font-semibold py-1 px-2 rounded`}
                        style={{
                          backgroundColor: item.label?.buttonColor ?? 'black',
                          width: 'auto',
                          color: 'white',
                          fontWeight: '600',
                        }}
                      >
                        {item.label?.buttonDisplayName}
                      </div>
                    </AppLink>
                  </div>
                </div>
              </div>
            ))}
        </div>
      )}
    </div>
  );
}
