import React from 'react';
import TopicMenu from '../../widgets/TopicMenu';
import { useTranslation } from 'next-i18next';
import AppLink from '../../Layout/handler/AppLink';
import { Beyondproject } from '@/models/HomePage.model';
import VideoProjectCard from './VideoProjectCard';
import SliderLayout from '../SliderLayout';

interface Props {
  projects?: Beyondproject[];
  showAll?: boolean;
}

export default function VideoProjects({ projects, showAll }: Props) {
  const { t } = useTranslation('home');
  return projects && projects.length > 0 ? (
    <div>
      <div
        className={`mt-1 flex items-center ${
          showAll ? 'justify-center' : 'justify-between'
        } px-0.5 sm:px-1`}
      >
        {!showAll && <div className="w-1/3 hidden md:block"></div>}
        <div
          className={`${showAll ? '' : 'w-1/2 md:w-1/3'} flex ${
            showAll ? 'justify-center' : 'justify-start'
          } md:justify-center`}
        >
          <TopicMenu topic={`${t('interestinglist')}`} />
        </div>
        {!showAll && (
          <div className="w-1/2 md:w-1/3 flex justify-end items-center text-sm sm:text-base">
            <AppLink href={`/projects`}>
              <div className="border bg-white hover:border-primary px-2 py-0.5 rounded-full flex items-center select-none cursor-pointer">
                <div className="text-primary whitespace-nowrap">
                  {t('common:viewall')}
                </div>
                <div className="h-4 leading-4 ms-1">
                  <i className="bi bi-arrow-right-circle-fill text-primary"></i>
                </div>
              </div>
            </AppLink>
          </div>
        )}
      </div>
      <div className="pt-2 pb-3 px-1 xl:px-0">
        <SliderLayout
          dots={true}
          arrows={true}
          autoplay={true}
          infinite={false}
          slidesToShow={3}
          xs={{ slidesToShow: 1 }}
          sm={{ slidesToShow: 1 }}
          md={{ slidesToShow: 2 }}
          lg={{ slidesToShow: 2 }}
        >
          {projects.map((e, i) => {
            e.url = `${e.url}&autoplay=1`;
            return (
              <div key={i} className="px-1">
                <VideoProjectCard project={e} />
              </div>
            );
          })}
        </SliderLayout>
      </div>
    </div>
  ) : (
    <></>
  );
}
