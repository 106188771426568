import React from 'react';
import TopicMenu from '../../widgets/TopicMenu';
import AppLink from '../../Layout/handler/AppLink';
import { useTranslation } from 'next-i18next';
import { Banner } from '@/models/HomePage.model';
import SliderLayout from '../../common/SliderLayout';
import PromotionImageImpression from '../../common/PromotionImageImpression';
import PromotionSlideLoading from './Loading';

interface Props {
  banners?: Banner[];
}

export default function PromotionSlide({ banners }: Props) {
  const { t } = useTranslation('home');
  const desktopWidth = 890;
  const desktopHeight = 480;

  return banners === undefined || banners.length === 0 ? (
    <>
      <PromotionSlideLoading
        desktopWidth={desktopWidth}
        desktopHeight={desktopHeight}
      />
    </>
  ) : (
    <>
      <div className="bg-white p-2">
        <div className="flex items-center justify-between">
          <TopicMenu topic={`${t('hotpromotion')}`} />
          <AppLink href="/promotions">
            <div className="border hover:border-primary px-2 py-0.5 rounded-full flex items-center select-none cursor-pointer text-sm sm:text-base">
              <div className="text-primary whitespace-nowrap">
                {t('common:viewall')}
              </div>
              <div className="h-4 leading-4 ms-1">
                <i className="bi bi-arrow-right-circle-fill text-primary"></i>
              </div>
            </div>
          </AppLink>
        </div>
        <div className="pt-2 pb-3">
          <SliderLayout
            dots={true}
            arrows={true}
            autoplay={true}
            centerMode
            slidesToShow={3}
            xs={{ slidesToShow: 1 }}
            sm={{ slidesToShow: 1 }}
            md={{ slidesToShow: 2 }}
            lg={{ slidesToShow: 2 }}
          >
            {banners?.map((e, i) => (
              <div key={`slider-${i}`} className="px-1 md:px-1.5">
                <div>
                  <PromotionImageImpression
                    sectionName={'promotionslide'}
                    position={i + 1}
                    banner={e}
                    width={desktopWidth}
                    height={desktopHeight}
                    loading="lazy"
                    imageRounded
                  />
                </div>
              </div>
            ))}
          </SliderLayout>
        </div>
      </div>
    </>
  );
}
