import { Brand } from '@/models/HomePage.model';
import React from 'react';
import { useTranslation } from 'next-i18next';
import TopicMenu from '../widgets/TopicMenu';
import SliderLayout from '../common/SliderLayout';
import AppLink from '../Layout/handler/AppLink';
import Image from 'next/image';

export default function ShopByBrand({ brands }: { brands?: Brand[] }) {
  const { t } = useTranslation('home');
  return (
    <>
      {brands ? (
        <div className="p-2 bg-white">
          <div className="flex items-center justify-center px-0 sm:px-1">
            <TopicMenu topic={`${t('shopbybrand')}`} />
          </div>
          <div className="pt-2 pb-3">
            <SliderLayout
              dots={true}
              arrows={true}
              slidesToShow={6}
              xs={{
                slidesToShow: 3,
                slidesPerRow: 2,
              }}
              sm={{
                slidesToShow: 3,
                slidesPerRow: 2,
              }}
              md={{
                slidesToShow: 3,
                slidesPerRow: 2,
              }}
              lg={{
                slidesToShow: 3,
                slidesPerRow: 2,
              }}
              infinite={false}
            >
              {brands.map((e, i) => (
                <div key={`slider-${i}`} className="px-1 md:px-1.5 select-none">
                  <AppLink href={`/brand/${e.brand}`} className="select-none">
                    <div className="h-10 sm:h-[4.5rem] flex items-center justify-center">
                      <Image
                        src={e.img}
                        alt={e.brand}
                        width={240}
                        height={100}
                        className="w-full lg:w-4/5"
                      />
                    </div>
                  </AppLink>
                </div>
              ))}
            </SliderLayout>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
